import { FripKind, ProductState } from '@/domain/product/model/product/enum';

export enum ListingProductType {
  // # 전체
  WHOLE = 'WHOLE',

  // # 카테고리별영역 - 신규
  NEW = 'NEW',

  // # 카테고리별영역 - 특가
  DEAL = 'DEAL',

  // # 카테고리별영역 - 인기
  HOT = 'HOT',

  // # 카테고리별영역 - 내주변
  NEARBY = 'NEARBY',
}

export interface ListingProductsParam {
  // # 카테고리아이디
  categoryId: string;

  // # 큐레이션타입
  listingProductType: ListingProductType;

  // # 페이지(1부터시작)
  page?: number;

  // # 페이지별갯수
  pageSize?: number;

  //# 필터
  filter: ListingProductFilter;
}

export interface ListingProductTerm {
  startDate: number;
  endDate: number;
}

export interface ListingProductPriceRange {
  minPrice: number;
  maxPrice: number;
}

export interface ListingProductFilter {
  // # 상품 아이디 목록
  ids?: string[];
  // # 당일사용가능상품
  isInstant?: boolean;
  // # 슈퍼호스트상품
  isSuperHost?: boolean;
  // # 지역태그
  locationTag?: number;
  // # 누구와 태그 목록
  socialTypeTags?: number[];
  // # 가격범위
  priceRange?: ListingProductPriceRange;
  // # 기간 (Instant 형태로 전달해야 함)
  term?: ListingProductTerm;
  // # 검색어
  searchString?: string;
  // # 전시지역아이디
  displayAreaId?: string;
  //# 호스트 아이디
  hostId?: string;
  // # 프립 종류
  fripKindIn?: FripKind[];
}

interface PageInfo {
  hasNextPage: boolean;
  endCursor?: string;
}

export interface ListingProductConnection {
  totalCount: number;
  pageInfo: PageInfo;
  edges: ListingProductEdge[];
}

export interface ListingProductEdge {
  cursor: string;
  node: ListingProduct;
}

export interface ListingProduct {
  // # 아이디
  id: string;
  // # 제목
  title: string;
  //# 캐치프레이즈
  catchphrase?: string;
  //# 원가격 (기존 fakedPrice)
  price: number;
  // # 판매가격 (기존 price)
  salePrice: number;

  // # 상품타입
  // # (기존 displayType을 문자열로 표현하고,
  // # 이벤트 상품을 표현하던 11번은 ETC로 표현)
  type: ProductType;
  // # 리뷰갯수
  reviewCount: number;
  //# 평점
  rating: number;
  //# 대문이미지
  headerContents: { content: { id: string } }[];

  //# 마감임박여부
  almostSoldOut: boolean;

  // # 판매가능여부
  status: ProductState;

  isBenepia: boolean;
}

enum ProductType {
  // # 일정 상품
  FRIP_ITINERARY = 'FRIP_ITINERARY',
  // # 티켓상품
  FRIP_TICKET = 'FRIP_TICKET',
  // # 일정/티켓 이외의 상품은 ETC로 처리
  FRIP_ETC = 'FRIP_ETC',
}
